import axios from 'axios';
import Cookie from 'js-cookie';

async function schoolInfo({ isAuthenticated }) {
    if (!isAuthenticated) return null;
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${Cookie.get('access')}`,
            'Accept': 'application/json'
        },
    };
    
    try {
        const response = await axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/school/`, config);
        return response.data;
    } catch (e) {
        throw new Error(e);
    }
}

export default schoolInfo