import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    CircularProgress,
    Box,
    Backdrop,
    Paper,
    Typography,
} from "@mui/material";
import ScoringWizLogo from "../../assets/LogoScoringWizLogo.png";
import ScoringWiz from "../../assets/android-chrome-192x192.png";
import GoogleAd from "./GoogleAd";

export default function LiveScoringHockey({ hockeyGames }) {
    const { gameId } = useParams();
    const [gameDetails, setGameDetails] = useState(null);

    useEffect(() => {
        // Fetch the game details based on gameId
        const selectedGame = hockeyGames.find((game) => game.id === gameId);
        setGameDetails(selectedGame);
    }, [gameId, hockeyGames]);

    if (!gameDetails) {
        return (
            <Backdrop open={!gameDetails}>
                <CircularProgress color="secondary" />
            </Backdrop>
        );
    }

    const goHome = () => {
        window.location.href = `${import.meta.env.VITE_FRONTEND_URL}`;
    };

    function displayTimer() {
        if (gameDetails.time.isHalfTime) {
            return (
                <Typography variant="h6" component="h2">
                    Half Time
                </Typography>
            )
        } else {
            if (gameDetails.time.quarter === 1) {
                return (
                    <Typography variant="h6" component="h2" sx={{ textAlign: 'center', fontSize: { xs: 12, md: 25 }, }}>
                        {gameDetails.time.q1Time === 1 ? `${gameDetails.time.q1Time} minute` : `${gameDetails.time.q1Time} minutes`}
                    </Typography>
                )
            } else if (gameDetails.time.quarter === 2) {
                return (
                    <Typography variant="h6" component="h2" sx={{ textAlign: 'center', fontSize: { xs: 12, md: 25 }, }}>
                        {gameDetails.time.q2Time === 1 ? `${gameDetails.time.q2Time} minute` : `${gameDetails.time.q2Time} minutes`}
                    </Typography>
                )
            }  else if (gameDetails.time.quarter === 3) {
                return (
                    <Typography variant="h6" component="h2" sx={{ textAlign: 'center', fontSize: { xs: 12, md: 25 }, }}>
                        {gameDetails.time.q3Time === 1 ? `${gameDetails.time.q3Time} minute` : `${gameDetails.time.q3Time} minutes`}
                    </Typography>
                )
            }   else if (gameDetails.time.quarter === 4) {
                return (
                    <Typography variant="h6" component="h2" sx={{ textAlign: 'center', fontSize: { xs: 12, md: 25 }, }}>
                        {gameDetails.time.q4Time === 1 ? `${gameDetails.time.q4Time} minute` : `${gameDetails.time.q4Time} minutes`}
                    </Typography>
                )
            }
        }
    }

    return (
        <Box component={"main"}>
            <Box
                component={"section"}
                sx={{ height: { xs: 100, md: 173 }, width: { xs: 140, md: 245 } }}
                onClick={goHome}
            >
                <img
                src={ScoringWizLogo}
                alt="ScoringWiz Logo"
                style={{ borderRadius: "0 100px 100px 0", cursor: "pointer" }}
                />
            </Box>
            <Box
                component={"section"}
                sx={{
                    height: {
                        xs: "calc(100dvh - 100px - 80px)",
                        md: "calc(100dvh - 173px - 120px)",
                    },
                    width: { xs: "95dvw", md: "50dvw" },
                    marginInline: "auto",
                    alignContent: "center",
                }}
            >
                <Paper
                    elevation={4}
                    sx={{
                        paddingX: { xs: 1, md: 2 },
                        paddingY: { xs: 3, md: 8 },
                        marginTop: 2,
                    }}
                >
                    {/* Timer */}
                    <Paper
                        elevation={10}
                        sx={{
                        width: "95%",
                        marginInline: "auto",
                        padding: { xs: 0.75, md: 1.5 },
                        }}
                    >
                        {displayTimer()}
                    </Paper>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: "space-evenly",
                        marginTop: 2,
                        }}
                    >
                        <Paper
                            elevation={10}
                            sx={{
                                textAlign: "center",
                                width: { xs: 140, md: 230 },
                                height: { xs: 100, md: 140 },
                                color: "#053F5C",
                            }}
                        >
                            <Typography
                                variant="h5"
                                component={"p"}
                                sx={{
                                    marginTop: 2,
                                    fontSize: { xs: 12, md: 20 },
                                }}
                            >
                                {gameDetails.homeName}
                            </Typography>
                            <Typography 
                                sx={{
                                    marginTop: {md: 2},
                                    fontSize: { xs: 48, md: 64 },
                                }}
                            >
                                {gameDetails.homeScore}
                            </Typography>
                        </Paper>
                        <Typography
                            sx={{
                                alignSelf: "center",
                                textAlign: "center",
                            }}
                            variant="h3"
                            component={"p"}
                            color="primary"
                        >
                            vs
                        </Typography>
                        <Paper
                            elevation={10}
                            sx={{
                                textAlign: "center",
                                width: { xs: 140, md: 230 },
                                height: { xs: 100, md: 140 },
                                color: "#053F5C",
                            }}
                        >
                            <Typography
                                variant="h5"
                                component={"p"}
                                sx={{
                                    marginTop: 2,
                                    fontSize: { xs: 12, md: 20 },
                                }}
                            >
                                {gameDetails.awayName}
                            </Typography>
                            <Typography 
                                sx={{
                                    marginTop: {md: 2},
                                    fontSize: { xs: 48, md: 64 },
                                }}
                            >
                                {gameDetails.awayScore}
                            </Typography>
                        </Paper>
                    </Box>
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: "space-evenly",
                        marginTop: 2,
                        }}
                    >
                        <Box>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Shot at Goals: {gameDetails.homeShotAttempts}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Short Corners: {gameDetails.shortCornerHome}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Short C Converted: {gameDetails.shortCornerHomeSuccess}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Strokes: {gameDetails.penStrokeHome}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Strokes Converted: {gameDetails.penStrokeHomeSuccess}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Green Cards: {gameDetails.cards.homeGreens}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Yellow Cards: {gameDetails.cards.homeYellows}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Red Cards: {gameDetails.cards.homeReds}</Typography>
                        </Box>
                        <Box
                            sx={{
                            height: { xs: 55, md: 125 },
                            width: { xs: 55, md: 125 },
                            marginTop: 0.5,
                            backgroundImage: `url(${ScoringWiz})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: { xs: "55px 55px", md: "125px 125px" },
                            opacity: 0.25,
                            alignSelf: 'center'
                            }}
                        ></Box>
                        <Box>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Shot at Goals: {gameDetails.awayShotAttempts}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Short Corners: {gameDetails.shortCornerAway}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Short C Converted: {gameDetails.shortCornerAwaySuccess}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Strokes: {gameDetails.penStrokeAway}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Strokes Converted: {gameDetails.penStrokeAwaySuccess}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Green Cards: {gameDetails.cards.awayGreens}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Yellow Cards: {gameDetails.cards.awayYellows}</Typography>
                            <Typography sx={{ fontSize: {xs: "0.75em", md: '1em'} }}>Red Cards: {gameDetails.cards.awayReds}</Typography>
                        </Box>
                    </Box>
                </Paper>
            </Box>
            <GoogleAd />
        </Box>
    );
}

LiveScoringHockey.propTypes = {
    hockeyGames: PropTypes.array,
};
