import style from "./utils.module.css";
import { useState } from "react";
import ScoringWizLogo from "../assets/LogoScoringWizLogo.png";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Avatar,
  Divider,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Menu,
  MenuItem,
  Typography,
  Collapse,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/actions/auth";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SportsRugbyIcon from "@mui/icons-material/SportsRugby";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsHockeyIcon from "@mui/icons-material/SportsHockey";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import InfoIcon from "@mui/icons-material/Info";
import PropTypes from 'prop-types';

export default function NavBar({ data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const authUser = useSelector((state) => state.auth.user);
  const is_schoolAdmin = authUser?.is_schoolAdmin;
  const [anchorEl, setAnchorEl] = useState(false);
  const open = anchorEl;
  const [statsAnchorEl, setStatsAnchorEl] = useState(null);
  const statsOpen = Boolean(statsAnchorEl);
  const [mobileStatsOpen, setMobileStatsOpen] = useState(false);

  const handleClick = (e) => {
    if (e.currentTarget.id === "open_drawer") {
      setAnchorEl(true);
    } else if (e.currentTarget.id === "statsButton") {
      setStatsAnchorEl(e.currentTarget);
    } else if (e.currentTarget.id === "mobileStatsButton") {
      setMobileStatsOpen(!mobileStatsOpen);
    }
  };

  const handleClose = (e) => {
    if (e.currentTarget.id === "close_drawer") {
      setAnchorEl(false);
    }
  };

  const handleStatsClose = () => {
    setStatsAnchorEl(null);
  };

  function authLogin(e) {
    if (e.currentTarget.id === "logout") {
      dispatch(logout());
      navigate("/");
    }
    if (isAuthenticated) {
      if (e.currentTarget.id === "scoring") {
        navigate("/scoring");
      } else if (e.currentTarget.id === "athlete") {
        navigate("/athletes");
      } else if (e.currentTarget.id === "account") {
        navigate("/my_account");
      } else if (e.currentTarget.id === "rugbyStats") {
        navigate("/stats/rugby");
      } else if (e.currentTarget.id === "netballStats") {
        navigate("/stats/netball");
      } else if (e.currentTarget.id === "hockeyStats") {
        navigate("/stats/hockey");
      } else if (e.currentTarget.id === "tennisStats") {
        navigate("/stats/tennis");
      } else if (e.currentTarget.id === "cricketStats") {
        navigate("/stats/cricket");
      }
    } else {
      navigate("/login");
    }
  }

  const homeClick = () => navigate("/");
  document.addEventListener("logo", (event) => event.preventDefault());

  const mobileScreen = () => {
    return (
      <List>
        <ListItem sx={{ display: { sm: "block", md: "none" } }}>
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText onClick={homeClick}>Home</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ display: { sm: "block", md: "none" } }}>
          <ListItemButton id="mobileStatsButton" onClick={handleClick}>
            <ListItemIcon>
              <AnalyticsIcon />
            </ListItemIcon>
            <ListItemText primary="Stats" />
            {mobileStatsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>
        <Collapse in={mobileStatsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {data && (
              <>
                {data?.rugby && (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    id="rugbyStats"
                    onClick={authLogin}
                  >
                    <ListItemIcon>
                      <SportsRugbyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Rugby Stats" />
                  </ListItemButton>
                )}
                {data?.cricket && (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    id="cricketStats"
                    onClick={authLogin}
                  >
                    <ListItemIcon>
                      <SportsCricketIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cricket Stats" />
                  </ListItemButton>
                )}
                {data?.tennis && (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    id="tennisStats"
                    onClick={authLogin}
                  >
                    <ListItemIcon>
                      <SportsTennisIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tennis Stats" />
                  </ListItemButton>
                )}
                {data?.netball && (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    id="netballStats"
                    onClick={authLogin}
                  >
                    <ListItemIcon>
                      <SportsBasketballIcon />
                    </ListItemIcon>
                    <ListItemText primary="Netball Stats" />
                  </ListItemButton>
                )}
                {data?.hockey && (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={authLogin}
                    id="hockeyStats"
                  >
                    <ListItemIcon>
                      <SportsHockeyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Hockey Stats" />
                  </ListItemButton>
                )}
              </>
            )}
          </List>
        </Collapse>
        <ListItem sx={{ display: { sm: "block", md: "none" } }}>
          <ListItemButton>
            <ListItemIcon>
              <SportsHandballIcon />
            </ListItemIcon>
            <ListItemText onClick={authLogin} id="athlete">
              Athlete
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ display: { sm: "block", md: "none" } }}>
          <ListItemButton id="scoring" onClick={authLogin}>
            <ListItemIcon>
              <ScoreboardIcon />
            </ListItemIcon>
            <ListItemText>Scoring</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    );
  };

  return (
    <header className={style.nav}>
      <nav className={style.navigation}>
        <img
          className={style.logo}
          src={ScoringWizLogo}
          alt="ScoringWiz Logo"
        />
        <ul className={style.nav__list}>
          <li className={style.nav__list_item} onClick={homeClick}>
            Home
          </li>
          <Typography
            id="statsButton"
            aria-controls={
              statsOpen ? "Stats menu with different sport types" : undefined
            }
            aria-haspopup="true"
            aria-expanded={statsOpen ? "true" : undefined}
            onClick={handleClick}
            className={style.nav__list_item}
            component={"li"}
            sx={{ fontSize: "1.25rem", fontFamily: "system-ui, sans-serif" }}
          >
            Stats
          </Typography>
          <Menu
            id="statsButtonList"
            anchorEl={statsAnchorEl}
            open={statsOpen}
            onClose={handleStatsClose}
            MenuListProps={{
              "aria-labelledby": "Stats button to take you to stats page",
            }}
          >
            <div>
              {data?.rugby && (
                <MenuItem id="rugbyStats" onClick={authLogin}>
                  Rugby Stats
                </MenuItem>
              )}
              {data?.cricket && (
                <MenuItem id="cricketStats" onClick={authLogin}>
                  Cricket Stats
                </MenuItem>
              )}
              {data?.tennis && (
                <MenuItem id="tennisStats" onClick={authLogin}>
                  Tennis Stats
                </MenuItem>
              )}
              {data?.netball && (
                <MenuItem id="netballStats" onClick={authLogin}>
                  Netball Stats
                </MenuItem>
              )}
              {data?.hockey && (
                <MenuItem id="hockeyStats" onClick={authLogin}>
                  Hockey Stats
                </MenuItem>
              )}
            </div>
          </Menu>
          <li className={style.nav__list_item} id="athlete" onClick={authLogin}>
            Athlete
          </li>
          <li className={style.nav__list_item} id="scoring" onClick={authLogin}>
            Scoring
          </li>
          <li className={style.nav__list_logo}>
            <IconButton
              id="open_drawer"
              onClick={handleClick}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              aria-label="Drawer with more options"
              sx={{ height: 50, alignSelf: "center", width: 50 }}
            >
              <Avatar
                variant="square"
                sx={{
                  width: 70,
                  height: 70,
                  "& img": {
                    objectFit: "scale-down",
                    width: "100%",
                    height: "100%",
                  },
                }}
                src={
                  data !== undefined || null
                    ? `${import.meta.env.VITE_BACKEND_URL}${data?.school_logo}`
                    : ""
                }
                alt="School Logo"
              />
            </IconButton>
          </li>
          <SwipeableDrawer
            open={anchorEl}
            onClose={handleClose}
            onOpen={handleClick}
            anchor="right"
          >
            <List>
              <ListItem sx={{ width: "100%", display: "flex" }}>
                <IconButton
                  id="close_drawer"
                  onClick={handleClose}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  sx={{ marginX: "auto" }}
                >
                  <Avatar
                    variant="square"
                    sx={{
                      width: 70,
                      height: 70,
                      "& img": {
                        objectFit: "scale-down",
                        width: "100%",
                        height: "100%",
                      },
                    }}
                    src={
                      data !== undefined
                        ? `${import.meta.env.VITE_BACKEND_URL}${
                            data?.school_logo
                          }`
                        : ""
                    }
                    alt="School Logo"
                  />
                </IconButton>
              </ListItem>
              <Divider />
              {mobileScreen()}
              {is_schoolAdmin ? (
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText onClick={authLogin} id="account">
                      My Account
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ) : null}
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <ContactSupportIcon />
                  </ListItemIcon>
                  <ListItemText onClick={() => navigate("/contact-us")}>
                    Contact Us
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <ListItemText onClick={() => navigate("/about-us")}>
                    About Us
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  {isAuthenticated ? <LogoutIcon /> : <LoginIcon />}
                </ListItemButton>
                {isAuthenticated ? (
                  <Button
                    id="logout"
                    onClick={authLogin}
                    variant="contained"
                    color="secondary"
                  >
                    Logout
                  </Button>
                ) : (
                  <Button
                    onClick={authLogin}
                    variant="contained"
                    color="secondary"
                  >
                    Login
                  </Button>
                )}
              </ListItem>
              <ListItem></ListItem>
            </List>
          </SwipeableDrawer>
        </ul>
      </nav>
    </header>
  );
}

NavBar.propTypes = {
  data: PropTypes.object,
};