import { Routes, Route } from "react-router-dom";
import LiveScoring from "./components/LiveScoring.jsx";
import LiveScoringTeam from "./components/LiveScoring/LiveScoringTeam.jsx";
import LiveScoringTennis from "./components/LiveScoring/LiveScoringTennis.jsx";
import LiveScoringNetball from "./components/LiveScoring/LiveScoringNetball.jsx";
import LiveScoringCricket from "./components/LiveScoring/LiveScoringCricket.jsx";
import LiveScoringHockey from "./components/LiveScoring/LiveScoringHockey.jsx";
import { useEffect, useState } from "react";
import { ref, onValue, off } from "firebase/database";
import { db } from "../firebase.js";
import { v4 as uuidv4 } from "uuid";

export default function LiveRouter() {
  const [rugbyGames, setRugbyGames] = useState([]);
  const [netballGames, setNetballGames] = useState([]);
  const [cricketGames, setCricketGames] = useState([]);
  const [hockeyGames, setHockeyGames] = useState([]);
  const [tennisGames, setTennisGames] = useState([]);
  const [uuidMap, setUuidMap] = useState({});

  useEffect(() => {
    // Reference to the 'games' node in your database
    const gamesRef = ref(db, "rugby");

    // Attach an asynchronous callback to read the data at the 'games' reference
    onValue(gamesRef, (snapshot) => {
      const gamesData = snapshot.val();
      if (gamesData) {
        const newUuidMap = {};
        const gamesArray = Object.keys(gamesData).flatMap((ageGroup) => {
          const gamesForAgeGroup = gamesData[ageGroup];
          return Object.keys(gamesForAgeGroup).map((gameId) => {
            const uuid = uuidMap[gameId] || uuidv4(); // Use existing UUID or generate a new one
            newUuidMap[gameId] = uuid;
            return {
              id: uuid,
              ageGroup: ageGroup,
              gameId: gameId, // Store the original game ID
              ...gamesForAgeGroup[gameId],
            };
          });
        });
        setUuidMap(newUuidMap);
        setRugbyGames(gamesArray);
      } else {
        // If there are no games in the database, set an empty array
        setRugbyGames([]);
      }
    });

    // Detach the event listener when the component unmounts
    return () => {
      // Detach the event listener
      off(gamesRef);
    };
  }, [uuidMap]);

  useEffect(() => {
    // Reference to the 'games' node in your database
    const gamesRef = ref(db, "tennis");

    // Attach an asynchronous callback to read the data at the 'games' reference
    onValue(gamesRef, (snapshot) => {
      const gamesData = snapshot.val();
      if (gamesData) {
        const newUuidMap = {};
        const gamesArray = Object.keys(gamesData).flatMap((school_name) => {
          const gamesForSchoolName = gamesData[school_name];
          return Object.keys(gamesForSchoolName).map((gameId) => {
            const uuid = uuidMap[gameId] || uuidv4(); // Use existing UUID or generate a new one
            newUuidMap[gameId] = uuid;
            return {
              id: uuid,
              school_name: school_name,
              gameId: gameId, // Store the original game ID
              ...gamesForSchoolName[gameId],
            };
          });
        });
        setUuidMap(newUuidMap);
        setTennisGames(gamesArray);
      } else {
        // If there are no games in the database, set an empty array
        setTennisGames([]);
      }
    });

    // Detach the event listener when the component unmounts
    return () => {
      // Detach the event listener
      off(gamesRef);
    };
  }, [uuidMap]);

  useEffect(() => {
    // Reference to the 'games' node in your database
    const gamesRef = ref(db, "netball");

    // Attach an asynchronous callback to read the data at the 'games' reference
    onValue(gamesRef, (snapshot) => {
      const gamesData = snapshot.val();
      if (gamesData) {
        const newUuidMap = {};
        const gamesArray = Object.keys(gamesData).flatMap((ages) => {
          const gamesForSchoolName = gamesData[ages];
          return Object.keys(gamesForSchoolName).map((homeName) => {
            const uuid = uuidMap[homeName] || uuidv4(); // Use existing UUID or generate a new one
            newUuidMap[homeName] = uuid;
            return {
              id: uuid,
              ages: ages,
              homeName: homeName, // Store the original game ID
              ...gamesForSchoolName[homeName],
            };
          });
        });
        setUuidMap(newUuidMap);
        setNetballGames(gamesArray);
      } else {
        // If there are no games in the database, set an empty array
        setNetballGames([]);
      }
    });

    // Detach the event listener when the component unmounts
    return () => {
      // Detach the event listener
      off(gamesRef);
    };
  }, [uuidMap]);

  useEffect(() => {
    // Reference to the 'games' node in your database
    const gamesRef = ref(db, "cricket");

    // Attach an asynchronous callback to read the data at the 'games' reference
    onValue(gamesRef, (snapshot) => {
      const gamesData = snapshot.val();
      if (gamesData) {
        const newUuidMap = {};
        const gamesArray = Object.keys(gamesData).flatMap((ages) => {
          const gamesForSchoolName = gamesData[ages];
          return Object.keys(gamesForSchoolName).map((homeName) => {
            const uuid = uuidMap[homeName] || uuidv4(); // Use existing UUID or generate a new one
            newUuidMap[homeName] = uuid;
            return {
              id: uuid,
              ages: ages,
              homeName: homeName, // Store the original game ID
              ...gamesForSchoolName[homeName],
            };
          });
        });
        setUuidMap(newUuidMap);
        setCricketGames(gamesArray);
      } else {
        // If there are no games in the database, set an empty array
        setCricketGames([]);
      }
    });

    // Detach the event listener when the component unmounts
    return () => {
      // Detach the event listener
      off(gamesRef);
    };
  }, [uuidMap]);

  useEffect(() => {
    // Reference to the 'games' node in your database
    const gamesRef = ref(db, "hockey");

    // Attach an asynchronous callback to read the data at the 'games' reference
    onValue(gamesRef, (snapshot) => {
      const gamesData = snapshot.val();
      if (gamesData) {
        const newUuidMap = {};
        const gamesArray = Object.keys(gamesData).flatMap((ageGroup) => {
          const gamesForAgeGroup = gamesData[ageGroup];
          return Object.keys(gamesForAgeGroup).map((gameId) => {
            const uuid = uuidMap[gameId] || uuidv4(); // Use existing UUID or generate a new one
            newUuidMap[gameId] = uuid;
            return {
              id: uuid,
              ageGroup: ageGroup,
              gameId: gameId, // Store the original game ID
              ...gamesForAgeGroup[gameId],
            };
          });
        });
        setUuidMap(newUuidMap);
        setHockeyGames(gamesArray);
      } else {
        // If there are no games in the database, set an empty array
        setHockeyGames([]);
      }
    });

    // Detach the event listener when the component unmounts
    return () => {
      // Detach the event listener
      off(gamesRef);
    };
  }, [uuidMap])

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <LiveScoring
              rugbyGames={rugbyGames}
              tennisGames={tennisGames}
              netballGames={netballGames}
              cricketGames={cricketGames}
              hockeyGames={hockeyGames}
            />
          }
        />
        <Route
          path="/rugby/:gameId"
          element={<LiveScoringTeam rugbyGames={rugbyGames} />}
        />
        <Route
          path="/tennis/:gameId"
          element={<LiveScoringTennis tennisGames={tennisGames} />}
        />
        <Route
          path="/netball/:gameId"
          element={<LiveScoringNetball netballGames={netballGames} />}
        />
        <Route
          path="/cricket/:gameId"
          element={<LiveScoringCricket cricketGames={cricketGames} />}
        />
        <Route
          path="/hockey/:gameId"
          element={<LiveScoringHockey hockeyGames={hockeyGames} />}
        />
      </Routes>
    </>
  );
}
