import { configureStore, combineReducers } from '@reduxjs/toolkit';
import auth from './redux/reducers/auth';

const staticReducers = {
    auth
};

const createReducerManager = (initialReducers) => {
    const reducers = { ...initialReducers };

    const reducerManager = {
        getReducerMap: () => reducers,

        reduce: (state, action) => {
            const combinedReducer = combineReducers(reducers);
            return combinedReducer(state, action);
        },

        add: (key, reducer) => {
        if (!key || reducers[key]) {
            return;
        }

        reducers[key] = reducer;
            reducerManager.replaceReducer();
        },

        remove: (key) => {
        if (!key || !reducers[key]) {
            return;
        }

        delete reducers[key];
            reducerManager.replaceReducer();
        },

        replaceReducer: () => {
        store.replaceReducer(reducerManager.reduce);
        },
    };

    return reducerManager;
};

const reducerManager = createReducerManager(staticReducers);

const store = configureStore({
    reducer: reducerManager.reduce,
});

store.reducerManager = reducerManager;

export default store;