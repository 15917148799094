import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore'; // You can import other Firebase services as needed

const firebaseConfig = {
  apiKey: "AIzaSyBlx6LUUrzZ-cwh_EdLNxbS9wF_NO0II-E",
  authDomain: "scoringwiz-live-scoring.firebaseapp.com",
  databaseURL: "https://scoringwiz-live-scoring-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "scoringwiz-live-scoring",
  storageBucket: "scoringwiz-live-scoring.appspot.com",
  messagingSenderId: "1016430623084",
  appId: "1:1016430623084:web:a278558e3b666a8ac99b49",
  measurementId: "G-CQ86GH90XR"
};

export const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);