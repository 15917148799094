import AppRouter from "../AppRouter"
import LiveRouter from "../LiveRouter"

export const APPS = [
    {
        subdomain: 'www',
        app: AppRouter,
        main: true
    },
    {
        subdomain: 'live',
        app: LiveRouter,
        main: false
    }
]