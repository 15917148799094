import { useEffect } from "react";
import { useLocation } from 'react-router-dom';

const MetaTags = () => {
    const location = useLocation();
    const HomeDescription = "The all in one sport scoring app"
    const AboutDescription = "What is ScoringWiz"
    const ContactDescription = "Talk to us"
    const ScoringDescription = "ScoringWiz is your go-to platform for scoring rugby, netball, hockey, cricket, or tennis games. Save and analyze team and athlete stats effortlessly with our integrated database. Start optimizing your team's performance today!"
    const LoginDescription = "Login to your ScoringWiz account"
    const SignupDescription = "Sign up today and join the ScoringWiz family"

    const HomeKeywords = "sport, scoring, app, cricket scoring app, rugby scoring app, netball scoring app, tennis scoring app, hockey scoring app, ScoringWiz";
    const AboutKeywords = "ScoringWiz, about";
    const ContactKeywords = "ScoringWiz, contact";
    const ScoringKeywords = "ScoringWiz, sport, scoring, rugby, netball, hockey, cricket, tennis";
    const LoginKeywords = "ScoringWiz, login";
    const SignupKeywords = "ScoringWiz, sign up";

    useEffect(() => {
        // Update meta tags based on the current URL
        if (location.pathname === "/") {
            document.title = "ScoringWiz";
            document.querySelector("meta[name='description']").setAttribute("content", HomeDescription);
            document.querySelector("meta[name='keywords']").setAttribute("content", HomeKeywords);
        } else if (location.pathname === "/about-us") {
            document.title = "About ScoringWiz";
            document.querySelector("meta[name='description']").setAttribute("content", AboutDescription);
            document.querySelector("meta[name='keywords']").setAttribute("content", AboutKeywords);
        } else if (location.pathname === "/contact-us") {
            document.title = "Contact ScoringWiz";
            document.querySelector("meta[name='description']").setAttribute("content", ContactDescription);
            document.querySelector("meta[name='keywords']").setAttribute("content", ContactKeywords);
        } else if (location.pathname === "/scoring") {
            document.title = "ScoringWiz";
            document.querySelector("meta[name='description']").setAttribute("content", ScoringDescription);
            document.querySelector("meta[name='keywords']").setAttribute("content", ScoringKeywords);
        } else if (location.pathname === "/login") {
            document.title = "ScoringWiz | Login";
            document.querySelector("meta[name='description']").setAttribute("content", LoginDescription);
            document.querySelector("meta[name='keywords']").setAttribute("content", LoginKeywords);
        } else if (location.pathname === "/register") {
            document.title = "ScoringWiz | Register";
            document.querySelector("meta[name='description']").setAttribute("content", SignupDescription);
            document.querySelector("meta[name='keywords']").setAttribute("content", SignupKeywords);
        }
    }, [location.pathname])

    return null;
}

export default MetaTags;