import { useEffect } from "react";
import { Box } from "@mui/material";

const GoogleAd = () => {
  useEffect(() => {
    if (window.adsbygoogle) {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
    <Box
      className="ad"
      sx={{ height: { xs: 80, md: 120 }, backgroundColor: "grey" }}
    >
      <ins
        className="adsbygoogle"
        style={{ display: "block" }} // Changed from a string to an object
        data-ad-client="ca-pub-6879849241807542"
        data-ad-slot="7179491484"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </Box>
  );
};

export default GoogleAd;
