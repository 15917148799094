import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { Paper, Box, Typography } from "@mui/material";
import styles from "./homepage.module.css";
import UnAuthNavBar from "./UnAuthNavBar";
import NavBar from "../utils/navBar";
import FooterPage from "../utils/FooterPage";
import MobileApp from "../assets/ScoringWizMobile.webp";
import RugbyLaptop from "../assets/RugbyLaptop.webp";
import NetballLaptop from "../assets/NetballLaptop.webp";
import TennisLaptop from "../assets/TennisLaptop.webp";
import TennisMobile from "../assets/TennisMobile.webp";
import HockeyLaptop from "../assets/HockeyLaptop.webp";
import CricketLaptop from "../assets/CricketLaptop.jpg";
import CricketMobile from "../assets/CricketMobile.webp";
import HockeyMobile from "../assets/HockeyMobile.webp";
import NetballMobile from "../assets/NetballMobile.webp";
import RugbyMobile from "../assets/RugbyMobile.webp";
import SportIcons from "../assets/HomePage/SportIcons.png";
import GooglePlay from '../assets/google_play.png'
// import ApplePlay from "../assets/app_store.png"
import SportIconsMobile from "../assets/HomePage/SportIconsMobile.png";

export default function HomePage({ isAuthenticated, data }) {
  const pricing = 125;
  const navigate = useNavigate();

  const sportsList = useMemo(
    () => [
      { title: "Rugby", img: RugbyLaptop, imgMobile: RugbyMobile },
      { title: "Netball", img: NetballLaptop, imgMobile: NetballMobile },
      { title: "Hockey", img: HockeyLaptop, imgMobile: HockeyMobile },
      { title: "Tennis", img: TennisLaptop, imgMobile: TennisMobile },
      { title: "Cricket", img: CricketLaptop, imgMobile: CricketMobile },
    ],
    []
  );

  const [currentSportIndex, setCurrentSportIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSportIndex((prevIndex) => (prevIndex + 1) % sportsList.length);
    }, 3500);

    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, [sportsList.length]);

  const muiStyles = {
    subPlanPaper: {
      overflow: "hidden",
      width: "18em",
      height: "20em"
    },
  };

  function handleNavigate(e) {
    if (e.currentTarget.id === 'live') {
      window.location.href = `${import.meta.env.VITE_FRONTEND_SUB_URL}`;
    } else if (e.currentTarget.id === 'register') {
      navigate('/register');
    }
  }

  return (
    <>
      {isAuthenticated ? <NavBar data={data} /> : <UnAuthNavBar />}
      <section className={styles.main}>
        <div className={styles.main__header}>
          <div className={styles.main__headerContainer}>
            <h1 className={styles.main__headerDescription}>
              Where the love of scoring and stats meet.
            </h1>
            <p className={styles.main__subHeaderDescription}>The only scoring app you will ever need</p>
            <div className={`${styles.main__Sports}`} >
              <Paper elevation={6} sx={{ padding: 2 }} className={styles.paper}>
                <div className={`${styles.rugbyIcon} ${styles.icons}`}></div>
                <p className={styles.main__SportsName}>Rugby</p>
              </Paper>
              <Paper elevation={6} sx={{ padding: 2 }} className={styles.paper}>
                <div className={`${styles.netballIcon} ${styles.icons}`}></div>
                <p className={styles.main__SportsName}>Netball</p>
              </Paper>
              <Paper elevation={6} sx={{ padding: 2 }} className={styles.paper}>
                <div className={`${styles.hockeyIcon} ${styles.icons}`}></div>
                <p className={styles.main__SportsName}>Hockey</p>
              </Paper>
              <Paper elevation={6} sx={{ padding: 2 }} className={styles.paper}>
                <div className={`${styles.tennisIcon} ${styles.icons}`}></div>
                <p className={styles.main__SportsName}>Tennis</p>
                </Paper>
              <Paper elevation={6} sx={{ padding: 2 }} className={styles.paper}>
                <div className={`${styles.cricketIcon} ${styles.icons}`}></div>
                <p className={styles.main__SportsName}>Cricket</p>
              </Paper>
            </div>
            <div className={styles.main__callToAction}>
              <button className={`${styles.main__registerBtn} ${styles.btn}`} id='register' onClick={(e) => handleNavigate(e)}>Register</button>
              <button className={`${styles.main__liveBtn} ${styles.btn}`} id='live' onClick={(e) => handleNavigate(e)}>Live Scores</button>
            </div>
          </div>
          <div className={styles.sportIcons}>
            <picture>
              <source srcSet={SportIconsMobile} media="(max-width: 750px)" className={styles.desktopHero} alt='sports icons'  />
              <img src={SportIcons} alt='sports icons' />
            </picture>
            <div className={styles.scrollSVG}></div>
          </div>
        </div>
      </section>
      <section className={styles.whatWeDo}>
        <Paper
          elevation={4}
          sx={{
            width: { xs: "90%", sm: "80%", md: "90%" },
            marginInline: {xs: "auto", xl: '0'},
            zIndex: 100,
          }}
        >
          <Box sx={{ padding: 4.2 }}>
            <Typography
              variant="h3"
              sx={{
                textAlign: "center",
                marginY: 1.1,
                fontSize: { xs: "1.75rem", md: "3rem" },
              }}
              color="secondary"
            >
              What is ScoringWiz?
            </Typography>
            <p className={styles.whatWeDo__description}>One Scoring App for all your school&apos;s sports.</p>
            <p className={styles.whatWeDo__description}>Live Scoring for parents to see scores of all games.</p>
            <ul className={styles.whatWeDo__description}>
              <li>Track stats as your team is playing</li>
              <li>Track your team&apos;s performance and stats</li>
              <li>Track each athlete&apos;s performance in your school</li>
            </ul>
            <p className={styles.whatWeDo__description}>Review season stats from your phone or computer.</p>
            <p className={styles.whatWeDo__description}>Share PDF&apos;s documents of detailed match reports with parents.</p>
          </Box>
        </Paper>
      </section>
      {/* The Using ScoringWiz Section */}
      <section className={styles.uses}>
        <div className={styles.uses__headerContainer}>
          <h2 className={styles.uses__header}>Using ScoringWiz for{" "}</h2>
          <h2 className={styles.uses__headerSpan}>{sportsList[currentSportIndex].title}</h2>
        </div>
        <div className={styles.uses__container}>
          <Paper
            elevation={4}
            sx={{ padding: 3, backgroundColor: "#429EBD", zIndex: 100, height: { xs: 300, md: 500} }}
            className={styles.uses__paper}
          >
            <h3 className={styles.uses__subheader}>
              On your Laptop
            </h3>
            <Box
              sx={{
                display: "flex",
                height: { xs: 200, md: 400},
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <img
                loading="lazy"
                className={`${styles.uses__desktopImg} ${styles.uses__img}`}
                src={sportsList[currentSportIndex].img}
                alt="Laptop version of the ScoringWiz app"
              />
            </Box>
          </Paper>
          <Paper elevation={4} sx={{ padding: 3, backgroundColor: "#053F5C", zIndex: 100, height: 500, display: 'grid', justifyItems: 'center' }} className={styles.uses__paper}>
            <h3 className={styles.uses__subheader}>
              On your mobile
            </h3>
            <Box
              sx={{
                padding: 2,
                height: { md: 400},
              }}
            >
              <img
                loading="lazy"
                className={`${styles.uses__mobileImg} ${styles.uses__img}`}
                src={sportsList[currentSportIndex].imgMobile}
                alt="Mobile version of the ScoringWiz app"
              />
            </Box>
          </Paper>
          <Paper elevation={4} sx={{ padding: 3, backgroundColor: "#429EBD", zIndex: 100, height: 500, display: 'grid', justifyItems: 'center' }} className={styles.uses__paper}>
            <h3 className={styles.uses__subheader}>
              On the mobile app
            </h3>
            <div className={styles.mobileApp__container}>
              <Box sx={{ padding: 2, height: { xs: 200, md: 350} }}>
                <img
                  loading="lazy"
                  className={`${styles.uses__AppImg} ${styles.uses__img}`}
                  src={MobileApp}
                  alt="Mobile version of the ScoringWiz app"
                />
              </Box>
              <img src={GooglePlay} className={styles.mobile__download} />
            </div>
            {/* <img src={ApplePlay} className={styles.mobile__download} /> */}
          </Paper>
        </div>
        </section>
        {/* Register Section */}
        <section className={styles.register__container}>
          <div>
          <h2 className={styles.register__header}>
            Subscription Plans
          </h2>
          <Box
            sx={{
              display: { xs: "grid", md: "flex" },
              justifyContent: { xs: "center", md: "space-between" },
              gap: 2,
              width: "90dvw",
              marginInline: 'auto'
            }}
          >
            <Paper elevation={6} sx={muiStyles.subPlanPaper}>
              <div className={`${styles.register__box} ${styles.register__box_orange}`}>
                <h3 className={styles.register__subheader}>
                  One Sporting Code
                </h3>
                <h4 className={styles.register__subheader}>
                  R{pricing} p/m
                </h4>
                <sup>(11 months)</sup>
              </div>
              <Box sx={{ marginY: 3 }}>
                <button
                  className={`${styles.register__btn} ${styles.register__btn_orange}`}
                  onClick={() => navigate("/register")}
                  aria-label="Go to Register Page"
                >
                  Register Now!
                </button>
              </Box>
            </Paper>
            <Paper elevation={6} sx={muiStyles.subPlanPaper}>
              <div className={`${styles.register__box} ${styles.register__box_teal}`}>
                <h3 className={styles.register__subheader}>
                  Two Sporting Codes
                </h3>
                <h4 className={styles.register__subheader}>
                  R{pricing * 2} p/m
                </h4>
                <sup>(11 months)</sup>
              </div>
              <Box sx={{ marginY: 3 }}>
                <button
                  className={`${styles.register__btn} ${styles.register__btn_teal}`}
                  onClick={() => navigate("/register")}
                  aria-label="Go to Register Page"
                >
                  Register Now!
                </button>
              </Box>
            </Paper>
            <Paper elevation={6} sx={muiStyles.subPlanPaper}>
              <div className={`${styles.register__box} ${styles.register__box_orange}`}>
                <h3 className={styles.register__subheader}>
                  Three Sporting Codes
                </h3>
                <h4 className={styles.register__subheader}>
                  R{pricing * 3} p/m
                </h4>
                <sup>(11 months)</sup>
              </div>
              <Box sx={{ marginY: 3 }}>
                <button
                  className={`${styles.register__btn} ${styles.register__btn_orange}`}
                  onClick={() => navigate("/register")}
                  aria-label="Go to Register Page"
                >
                  Register Now!
                </button>
              </Box>
            </Paper>
            <Paper elevation={6} sx={muiStyles.subPlanPaper}>
              <div className={`${styles.register__box} ${styles.register__box_teal}`}>
                <h3 className={styles.register__subheader}>
                  Four + Sporting Codes
                </h3>
                <h4 className={styles.register__subheader}>
                  R{pricing * 4} p/m
                </h4>
                <sup>(11 months)</sup>
              </div>
              <div>
              </div>
              <Box sx={{ marginY: 3 }}>
                <button
                  className={`${styles.register__btn} ${styles.register__btn_teal}`}
                  onClick={() => navigate("/register")}
                  aria-label="Go to Register Page"
                >
                  Register Now!
                </button>
              </Box>
            </Paper>
          </Box>
          </div>
        </section>
      <FooterPage />
    </>
  );
}

HomePage.propTypes = {
  isAuthenticated: PropTypes.bool,
  data: PropTypes.object,
};