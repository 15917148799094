import { Typography, Box } from "@mui/material";
const displayAwaySets = (setsNumber, gameDetails) => {
  if (setsNumber === 1) {
    return (
      <>
        <Typography variant="h3">{gameDetails.firstSetAway}</Typography>
        {gameDetails.firstSetTieAway > 0 ? (
          <Typography variant="body2">{gameDetails.firstSetTieAway}</Typography>
        ) : null}
      </>
    );
  } else if (setsNumber === 3) {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.firstSetAway}</Typography>
          {gameDetails.firstSetTieAway > 0 ? (
            <Typography variant="body2">
              {gameDetails.firstSetTieAway}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.secondSetAway}</Typography>
          {gameDetails.secondSetTieAway > 0 ? (
            <Typography variant="body2">
              {gameDetails.secondSetTieAway}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.thirdSetAway}</Typography>
          {gameDetails.thirdSetTieAway > 0 ? (
            <Typography variant="body2">
              {gameDetails.thirdSetTieAway}
            </Typography>
          ) : null}
        </Box>
      </Box>
    );
  } else if (setsNumber === 5) {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.firstSetAway}</Typography>
          {gameDetails.firstSetTieAway > 0 ? (
            <Typography variant="body2">
              {gameDetails.firstSetTieAway}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.secondSetAway}</Typography>
          {gameDetails.secondSetTieAway > 0 ? (
            <Typography variant="body2">
              {gameDetails.secondSetTieAway}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.thirdSetAway}</Typography>
          {gameDetails.thirdSetTieAway > 0 ? (
            <Typography variant="body2">
              {gameDetails.thirdSetTieAway}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.fourthSetAway}</Typography>
          {gameDetails.fourthSetTieAway > 0 ? (
            <Typography variant="body2">
              {gameDetails.fourthSetTieAway}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.fifthSetAway}</Typography>
          {gameDetails.fifthSetTieAway > 0 ? (
            <Typography variant="body2">
              {gameDetails.fifthSetTieAway}
            </Typography>
          ) : null}
        </Box>
      </Box>
    );
  }
};

export default displayAwaySets;
