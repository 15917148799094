import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Box,
  Backdrop,
  Paper,
  Typography,
} from "@mui/material";
import ScoringWizLogo from "../../assets/LogoScoringWizLogo.png";
import ScoringWiz from "../../assets/android-chrome-192x192.png";
import GoogleAd from "./GoogleAd";
import displayHomeSets from "./utils/displayHomeSets";
import displayAwaySets from "./utils/displayAwaySets";

export default function LiveScoringTeam({ tennisGames }) {
  const { gameId } = useParams();
  const [gameDetails, setGameDetails] = useState(null);

  useEffect(() => {
    // Fetch the game details based on gameId
    const selectedGame = tennisGames.find((game) => game.id === gameId);
    setGameDetails(selectedGame);
  }, [tennisGames, gameId]);

  if (!gameDetails) {
    return (
      <Backdrop open={!gameDetails}>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  }

  const goHome = () => {
    window.location.href = `${import.meta.env.VITE_FRONTEND_URL}`;
  };

  return (
    <Box component={"main"}>
      <Box
        component={"section"}
        sx={{ height: { xs: 100, md: 173 }, width: { xs: 140, md: 245 } }}
        onClick={goHome}
      >
        <img
          src={ScoringWizLogo}
          alt="ScoringWiz Logo"
          style={{ borderRadius: "0 100px 100px 0", cursor: "pointer" }}
        />
      </Box>
      <Box
        component={"section"}
        sx={{
          height: {
            xs: "calc(100dvh - 100px - 80px)",
            md: "calc(100dvh - 173px - 120px)",
          },
          width: { xs: "90dvw", md: "50dvw" },
          marginInline: "auto",
          alignContent: "center",
        }}
      >
        <Paper
          elevation={4}
          sx={{
            paddingX: { xs: 0.5, md: 2 },
            paddingY: { xs: 1, md: 8 },
            marginTop: { xs: 0.5, md: 2 },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Paper
              elevation={10}
              sx={{
                width: { xs: "100%", md: "35%" },
                padding: { xs: 0.75, md: 1.5 },
                display: { xs: "flex", md: "grid" },
                justifyContent: { xs: "space-between", md: "center" },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: { xs: "1rem", md: "1.5rem" },
                  textAlign: "center",
                }}
              >
                Total Time Played
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: "1rem", md: "1.5rem" },
                  textAlign: "center",
                }}
              >
                {gameDetails.timer?.minutes} minutes
              </Typography>
            </Paper>
          </Box>
          <Box
            sx={{
              display: { xs: "grid", md: "flex" },
              justifyContent: "space-evenly",
              marginTop: 2,
            }}
          >
            <Paper
              elevation={10}
              sx={{
                textAlign: "center",
                width: { xs: "80dvw", md: 300 },
                color: "#053F5C",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  component={"p"}
                  sx={{
                    marginTop: 2,
                    paddingLeft: 2,
                    fontFamily: "system-ui",
                  }}
                >
                  {gameDetails.homeName}
                </Typography>
                <Typography
                  variant="h4"
                  component={"p"}
                  sx={{
                    marginTop: 2,
                    fontFamily: "system-ui",
                    backgroundColor: "#F27F0C",
                    width: 50,
                    height: 50,
                    color: "#FAF9F6",
                    borderRadius: 4,
                    marginRight: 2,
                    alignContent: "center",
                  }}
                >
                  {gameDetails.currentScoreA}
                </Typography>
              </Box>
              <Typography
                variant="h2"
                component={"p"}
                sx={{
                  marginTop: 2,
                  fontFamily: "system-ui",
                }}
              >
                {displayHomeSets(gameDetails.sets, gameDetails)}
              </Typography>
            </Paper>
            <Typography
              sx={{
                alignSelf: "center",
                fontFamily: "Audiowide, system-ui",
                textAlign: "center",
              }}
              variant="h3"
              component={"p"}
              color="primary"
            >
              vs
            </Typography>
            <Paper
              sx={{
                textAlign: "center",
                width: { xs: "80dvw", md: 300 },
                color: "#053F5C",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  component={"p"}
                  sx={{
                    marginTop: 2,
                    paddingLeft: 2,
                    fontFamily: "system-ui",
                  }}
                >
                  {gameDetails.awayName}
                </Typography>
                <Typography
                  variant="h4"
                  component={"p"}
                  sx={{
                    marginTop: 2,
                    fontFamily: "system-ui",
                    backgroundColor: "#F27F0C",
                    width: 50,
                    height: 50,
                    color: "#FAF9F6",
                    borderRadius: 4,
                    marginRight: 2,
                    alignContent: "center",
                  }}
                >
                  {gameDetails.currentScoreB}
                </Typography>
              </Box>
              <Typography
                variant="h2"
                component={"p"}
                sx={{
                  marginTop: 2,
                  fontFamily: "system-ui",
                  fontSize: { xs: 48, md: 64 },
                }}
              >
                {displayAwaySets(gameDetails.sets, gameDetails)}
              </Typography>
            </Paper>
          </Box>
          <Box
            sx={{
              height: { xs: 55, md: 125 },
              marginTop: 0.5,
              backgroundImage: `url(${ScoringWiz})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              backgroundSize: { xs: "55px 55px", md: "125px 125px" },
              opacity: 0.25,
            }}
          ></Box>
        </Paper>
      </Box>
      <GoogleAd />
    </Box>
  );
}

LiveScoringTeam.propTypes = {
  tennisGames: PropTypes.array.isRequired,
};
