import style from "../utils/utils.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import ScoringWizLogo from "../assets/LogoScoringWizLogo.png";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

export default function UnAuthNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    if (isMenuActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMenuActive])

  function toggleMenu() {
    setIsMenuActive(() => !isMenuActive);
  }
  function handleClicks(e) {
    if (e.currentTarget.id === "home") {
      navigate("/");
    } else if (e.currentTarget.id === "scoring") {
      navigate("/scoring");
    } else if (e.currentTarget.id === "login") {
      navigate("/login", {state: {from: location.pathname}});
    }
  }

  const Drawer = ({ isMenuActive }) => {
    return (
      <>
        <li id="home" onClick={handleClicks} className={`${isMenuActive ? style.mobileNavList : style.hidden}`}>Home</li>
        {isMenuActive ? <hr /> : null}
        <li id="scoring" onClick={handleClicks} className={`${isMenuActive ? style.mobileNavList : style.hidden}`}>Scoring</li>
        {isMenuActive ? <hr /> : null}
        <li className={`${isMenuActive ? style.mobileNavList : style.hidden}`}>
          <button
            className={style.nav__list_btn}
            id="login"
            onClick={(e) => handleClicks(e)}
          >
            LOGIN
          </button>
        </li>
      </>
    )
  }

  return (
    <header className={`${style.nav} ${isMenuActive ? style.navActive : ''}`}>
      <nav className={`${style.navigation} `}>
        <img
          className={`${style.logo} ${isMenuActive ? style.logoActive : ''}`}
          src={ScoringWizLogo}
          alt="ScoringWiz Logo"
        />
        <ul className={`${style.nav__list} ${isMenuActive ? style.navigationActive : ''}`}>
          <li className={style.nav__list_item} id="home" onClick={handleClicks}>
            Home
          </li>
          <li
            className={style.nav__list_item}
            id="scoring"
            onClick={handleClicks}
          >
            Scoring
          </li>
          <li className={style.nav__list_buttonContainer}>
            <button
              className={style.nav__list_btn}
              id="login"
              onClick={(e) => handleClicks(e)}
            >
              LOGIN
            </button>
          </li>
          <button className={`${style.hamburgerMenu} ${isMenuActive ? style.hamburgerMenuBtn : ""}`} aria-controls="primary-navigation" aria-expanded={isMenuActive} onClick={() => toggleMenu()}>
            <svg stroke="var(--tertiary-orange)" className={style.hamburger} viewBox="0 0 100 100" width='30' height="30">
              <line className={`${style.hamburgerTop} ${style.line} ${isMenuActive ? style.close : style.active}`} x1="90" x2="10" y1="40" y2="40" strokeWidth="15" strokeLinecap="round" strokeDasharray="80" strokeDashoffset="0"></line>
              <line className={`${style.hamburgerBottom} ${style.line} ${isMenuActive ? style.close : style.active}`} x1="10" x2="90" y1="70" y2="70" strokeWidth="15" strokeLinecap="round" strokeDasharray="80" strokeDashoffset="0"></line>
            </svg>
          </button>
          <Drawer isMenuActive={isMenuActive} />
        </ul>
      </nav>
    </header>
  );
}

UnAuthNavBar.propTypes = {
  isMenuActive: PropTypes.bool,
}