import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import ScoringWizLogo from "../assets/LogoScoringWizLogo.png";

export default function LiveScoring({
  rugbyGames,
  tennisGames,
  netballGames,
  cricketGames,
  hockeyGames,
}) {
  const goHome = () => {
    window.location.href = `${import.meta.env.VITE_FRONTEND_URL}`;
  };

  return (
    <Box component={"main"}>
      <Box
        component={"section"}
        sx={{ height: 173, width: 245 }}
        onClick={goHome}
      >
        <img
          src={ScoringWizLogo}
          alt="ScoringWiz Logo"
          style={{ borderRadius: "0 100px 100px 0", cursor: "pointer" }}
        />
      </Box>
      <Box component={"section"}>
        <Typography
          variant="h3"
          component={"h1"}
          sx={{ textAlign: "center", marginY: 2, textDecoration: "underline" }}
        >
          All live Games
        </Typography>
        <Paper
          sx={{
            width: { xs: "75dvw", md: "50dvw" },
            marginInline: "auto",
            padding: 2,
          }}
        >
          <Typography
            variant="h4"
            component={"h2"}
            sx={{ textAlign: "center" }}
            color="#FD5901"
          >
            Rugby Scores
          </Typography>
          {rugbyGames.length > 0 ? (
            rugbyGames.map((game, index) => (
              <Typography key={index} variant="h5" component={"h3"}>
                {index === 0 ||
                rugbyGames[index - 1].ageGroup !== game.ageGroup ? (
                  <Typography
                    variant="h6"
                    component={"h4"}
                    sx={{
                      marginLeft: 2,
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    Under {game.ageGroup} Games
                  </Typography>
                ) : null}
                <Link
                  to={`/rugby/${game.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography
                    variant="h6"
                    component={"p"}
                    sx={{
                      transition: "color 350ms linear",
                      "&:hover": { color: "#429EBD" },
                    }}
                  >
                    {game.homeName} vs {game.awayName}
                  </Typography>
                </Link>
              </Typography>
            ))
          ) : (
            <Typography variant="h5" component={"h2"}>
              No Current Rugby Games
            </Typography>
          )}
        </Paper>
      </Box>
      {/* Block Ad */}
      <Box component={"section"} sx={{ marginTop: 2 }}>
        <Paper
          sx={{
            width: { xs: "75dvw", md: "50dvw" },
            marginInline: "auto",
            padding: 2,
          }}
        >
          <Typography
            variant="h4"
            component={"h2"}
            sx={{ textAlign: "center" }}
            color="#FD5901"
          >
            Cricket Scores
          </Typography>
          {cricketGames.length > 0 ? (
            cricketGames.map((game, index) => (
              <Typography key={index} variant="h5" component={"h3"}>
                {index === 0 || cricketGames[index - 1].ages !== game.ages ? (
                  <Typography
                    variant="h6"
                    component={"h4"}
                    sx={{
                      marginLeft: 2,
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    Under {game.ages} Games
                  </Typography>
                ) : null}
                <Link
                  to={`/cricket/${game.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography
                    variant="h6"
                    component={"p"}
                    sx={{
                      transition: "color 350ms linear",
                      "&:hover": { color: "#429EBD" },
                    }}
                  >
                    {game.homeName} vs {game.awayName}
                  </Typography>
                </Link>
              </Typography>
            ))
          ) : (
            <Typography variant="h5" component={"h2"}>
              No Current Cricket Matches
            </Typography>
          )}
        </Paper>
      </Box>
      {/* Block Ad */}
      <Box component={"section"} sx={{ marginTop: 2 }}>
        <Paper
          sx={{
            width: { xs: "75dvw", md: "50dvw" },
            marginInline: "auto",
            padding: 2,
          }}
        >
          <Typography
            variant="h4"
            component={"h2"}
            sx={{ textAlign: "center" }}
            color="#FD5901"
          >
            Netball Scores
          </Typography>
          {netballGames.length > 0 ? (
            netballGames.map((game, index) => (
              <Typography key={index} variant="h5" component={"h3"}>
                {index === 0 || netballGames[index - 1].ages !== game.ages ? (
                  <Typography
                    variant="h6"
                    component={"h4"}
                    sx={{
                      marginLeft: 2,
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    Under {game.ages} Games
                  </Typography>
                ) : null}
                <Link
                  to={`/netball/${game.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography
                    variant="h6"
                    component={"p"}
                    sx={{
                      transition: "color 350ms linear",
                      "&:hover": { color: "#429EBD" },
                    }}
                  >
                    {game.homeName} vs {game.awayName}
                  </Typography>
                </Link>
              </Typography>
            ))
          ) : (
            <Typography variant="h5" component={"h2"}>
              No Current Netball Matches
            </Typography>
          )}
        </Paper>
      </Box>
      {/* Block Ad */}
      <Box component={"section"} sx={{ marginTop: 2 }}>
        <Paper
          sx={{
            width: { xs: "75dvw", md: "50dvw" },
            marginInline: "auto",
            padding: 2,
          }}
        >
          <Typography
            variant="h4"
            component={"h2"}
            sx={{ textAlign: "center" }}
            color="#FD5901"
          >
            Tennis Games
          </Typography>
          {tennisGames.length > 0 ? (
            tennisGames.map((game, index) => (
              <Typography key={index} variant="h5" component={"h3"}>
                {index === 0 ||
                tennisGames[index - 1].school_name !== game.school_name ? (
                  <Typography
                    variant="h6"
                    component={"h4"}
                    sx={{
                      marginLeft: 2,
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    {game.school_name} Games
                  </Typography>
                ) : null}
                <Link
                  to={`/tennis/${game.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography
                    variant="h6"
                    component={"p"}
                    sx={{
                      transition: "color 350ms linear",
                      "&:hover": { color: "#429EBD" },
                    }}
                  >
                    {game.homeName} vs {game.awayName}
                  </Typography>
                </Link>
              </Typography>
            ))
          ) : (
            <Typography variant="h5" component={"h2"}>
              No Current Tennis Matches
            </Typography>
          )}
        </Paper>
      </Box>
      {/* Block Ad */}
      <Box component={"section"} sx={{ marginTop: 2 }}>
        <Paper
          sx={{
            width: { xs: "75dvw", md: "50dvw" },
            marginInline: "auto",
            padding: 2,
          }}
        >
          <Typography
            variant="h4"
            component={"h2"}
            sx={{ textAlign: "center" }}
            color="#FD5901"
          >
            Hockey Games
          </Typography>
          {hockeyGames.length > 0 ? (
            hockeyGames.map((game, index) => (
              <Typography key={index} variant="h5" component={"h3"}>
                {index === 0 ||
                hockeyGames[index - 1].homeName !== game.homeName ? (
                  <Typography
                    variant="h6"
                    component={"h4"}
                    sx={{
                      marginLeft: 2,
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    {game.homeName} Games
                  </Typography>
                ) : null}
                <Link
                  to={`/hockey/${game.id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography
                    variant="h6"
                    component={"p"}
                    sx={{
                      transition: "color 350ms linear",
                      "&:hover": { color: "#429EBD" },
                    }}
                  >
                    {game.homeName} vs {game.awayName}
                  </Typography>
                </Link>
              </Typography>
            ))
          ) : (
            <Typography variant="h5" component={"h2"}>
              No Current Hockey Matches
            </Typography>
          )}
        </Paper>
      </Box>
    </Box>
  );
}

LiveScoring.propTypes = {
  rugbyGames: PropTypes.array.isRequired,
  tennisGames: PropTypes.array.isRequired,
  netballGames: PropTypes.array.isRequired,
  cricketGames: PropTypes.array.isRequired,
  hockeyGames: PropTypes.array.isRequired,
};
