import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./liveScoringCSS.css";
import {
  CircularProgress,
  Box,
  Backdrop,
  Paper,
  Typography,
} from "@mui/material";
import ScoringWizLogo from "../../assets/LogoScoringWizLogo.png";
import ScoringWiz from "../../assets/android-chrome-192x192.png";
import GoogleAd from "./GoogleAd";

export default function LiveScoringCricket({ cricketGames }) {
  const { gameId } = useParams();
  const [gameDetails, setGameDetails] = useState(null);

  useEffect(() => {
    // Fetch the game details based on gameId
    const selectedGame = cricketGames.find((game) => game.id === gameId);
    setGameDetails(selectedGame);
  }, [gameId, cricketGames]);

  if (!gameDetails) {
    return (
      <Backdrop open={!gameDetails}>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  }

  const goHome = () => {
    window.location.href = `${import.meta.env.VITE_FRONTEND_URL}`;
  };

  return (
    <Box component={"main"}>
      <Box
        component={"section"}
        sx={{ height: { xs: 100, md: 173 }, width: { xs: 140, md: 245 } }}
        onClick={goHome}
      >
        <img
          src={ScoringWizLogo}
          alt="ScoringWiz Logo"
          style={{ borderRadius: "0 100px 100px 0", cursor: "pointer" }}
        />
      </Box>
      <Box
        component={"section"}
        sx={{
          height: {
            xs: "calc(100dvh - 100px - 80px)",
            md: "calc(100dvh - 173px - 120px)",
          },
          width: { xs: "90dvw", md: "50dvw" },
          marginInline: "auto",
          alignContent: "center",
        }}
      >
        <Paper
          elevation={4}
          sx={{
            paddingX: { xs: 1, md: 8 },
            paddingY: { xs: 0.5, md: 1 },
            marginY: 1,
          }}
        >
          <Box
            sx={{
              justifyContent: "space-evenly",
            }}
          >
            <Paper
              elevation={10}
              sx={{
                textAlign: "center",
                color: "#053F5C",
                display: { xs: "grid", md: "flex" },
              }}
            >
              <Box
                sx={{
                  width: 300,
                  padding: { xs: 1, md: 2 },
                  display: { xs: "flex", md: "grid" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginInline: "auto",
                }}
              >
                <Typography
                  variant="h5"
                  component={"p"}
                  sx={{
                    marginTop: 2,
                    fontFamily: "system-ui",
                    fontSize: { xs: 18, md: 26 },
                  }}
                >
                  {gameDetails.homeName}
                </Typography>
                <Typography
                  variant="h2"
                  component={"p"}
                  sx={{
                    marginTop: 2,
                    fontFamily: "system-ui",
                    fontSize: { xs: 34, md: 64 },
                  }}
                >
                  {gameDetails.homeRuns} for {gameDetails.homeWickets}
                </Typography>
              </Box>
              <Box sx={{ display: "grid" }}>
                <Typography
                  component={"p"}
                  sx={{
                    textDecorationLine: "underline",
                    fontSize: { xs: 18, md: 26 },
                  }}
                >
                  {gameDetails.battingTeam.battingTeam === "Home"
                    ? "Currently Batting"
                    : "Currently Bowling"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    justifyContent: "space-between",
                    width: { xs: "80dvw", md: 400 },
                    marginInline: "auto",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"p"}
                    sx={{
                      marginTop: 2,
                      fontFamily: "system-ui",
                      width: { xs: "35dvw", md: 200 },
                      fontSize: { xs: 14, md: 24 },
                    }}
                  >
                    {gameDetails.battingTeam.battingTeam === "Home"
                      ? `${gameDetails.stats.homeBat1.bat1HomeName}`
                      : `${gameDetails.stats.homeBowler1.bowler1HomeName}`}
                  </Typography>
                  <Typography
                    component={"p"}
                    sx={{
                      marginTop: 2,
                      fontFamily: "system-ui",
                      fontSize: { xs: 12, md: 20 },
                    }}
                  >
                    {gameDetails.battingTeam.battingTeam === "Home"
                      ? `${gameDetails.stats.homeBat1.bat1HomeRuns} from ${gameDetails.stats.homeBat1.bat1HomeBalls} balls`
                      : `${gameDetails.stats.homeBowler1.bowler1HomeRuns} / ${gameDetails.stats.homeBowler1.bowler1HomeWickets} from ${gameDetails.stats.homeBowler1.bowler1HomeOvers}`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    justifyContent: "space-between",
                    width: { xs: "80dvw", md: 400 },
                    marginInline: "auto",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"p"}
                    sx={{
                      marginTop: 2,
                      fontFamily: "system-ui",
                      width: { xs: "35dvw", md: 200 },
                      fontSize: { xs: 14, md: 24 },
                    }}
                  >
                    {gameDetails.battingTeam.battingTeam === "Home"
                      ? `${gameDetails.stats.homeBat2.bat2HomeName}`
                      : `${gameDetails.stats.homeBowler2.bowler2HomeName}`}
                  </Typography>
                  <Typography
                    component={"p"}
                    sx={{
                      marginTop: 2,
                      fontFamily: "system-ui",
                      fontSize: { xs: 12, md: 20 },
                    }}
                  >
                    {gameDetails.battingTeam.battingTeam === "Home"
                      ? `${gameDetails.stats.homeBat2.bat2HomeRuns} from ${gameDetails.stats.homeBat2.bat2HomeBalls} balls`
                      : `${gameDetails.stats.homeBowler2.bowler2HomeRuns} / ${gameDetails.stats.homeBowler2.bowler2HomeWickets} from ${gameDetails.stats.homeBowler2.bowler2HomeOvers}`}
                  </Typography>
                </Box>
              </Box>
            </Paper>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                marginY: 1,
              }}
            >
              <Box
                sx={{
                  height: { xs: 55, md: 125 },
                  marginTop: 0.5,
                  width: { xs: 50, md: 100 },
                  backgroundImage: `url(${ScoringWiz})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "bottom center",
                  backgroundSize: { xs: "55px 55px", md: "125px 125px" },
                  opacity: 0.25,
                }}
              ></Box>
              <Typography
                sx={{
                  alignSelf: "center",
                  fontFamily: "system-ui",
                  textAlign: "center",
                }}
                variant="h3"
                component={"p"}
                color="primary"
              >
                {gameDetails.battingTeam.battingTeam === "Home"
                  ? `${gameDetails.homeOvers}`
                  : `${gameDetails.awayOvers}`}{" "}
                Overs
              </Typography>
              <Box
                sx={{
                  height: { xs: 55, md: 125 },
                  width: { xs: 50, md: 100 },
                  marginTop: 0.5,
                  backgroundImage: `url(${ScoringWiz})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "bottom center",
                  backgroundSize: { xs: "55px 55px", md: "125px 125px" },
                  opacity: 0.25,
                }}
              ></Box>
            </Box>
            <Paper
              elevation={10}
              sx={{
                textAlign: "center",
                color: "#FD5901",
                display: { xs: "grid", md: "flex" },
              }}
            >
              <Box
                sx={{
                  width: 300,
                  padding: { xs: 1, md: 2 },
                  display: { xs: "flex", md: "grid" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginInline: "auto",
                }}
              >
                <Typography
                  variant="h5"
                  component={"p"}
                  sx={{
                    marginTop: 2,
                    fontFamily: "system-ui",
                    fontSize: { xs: 18, md: 26 },
                  }}
                >
                  {gameDetails.awayName}
                </Typography>
                <Typography
                  variant="h2"
                  component={"p"}
                  sx={{
                    marginTop: 2,
                    fontFamily: "system-ui",
                    fontSize: { xs: 34, md: 64 },
                  }}
                >
                  {gameDetails.awayRuns} for {gameDetails.awayWickets}
                </Typography>
              </Box>
              <Box sx={{ display: "grid" }}>
                <Typography
                  component={"p"}
                  sx={{
                    textDecorationLine: "underline",
                    fontSize: { xs: 18, md: 26 },
                  }}
                >
                  {gameDetails.battingTeam.battingTeam === "Home"
                    ? "Currently Bowling"
                    : "Currently Batting"}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    justifyContent: "space-between",
                    width: { xs: "80dvw", md: 400 },
                    marginInline: "auto",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"p"}
                    sx={{
                      marginTop: 2,
                      fontFamily: "system-ui",
                      width: { xs: "35dvw", md: 200 },
                      fontSize: { xs: 14, md: 24 },
                    }}
                  >
                    {gameDetails.battingTeam.battingTeam === "Home"
                      ? `${gameDetails.stats.awayBowler1.bowler1AwayName}`
                      : `${gameDetails.stats.awayBat1.bat1AwayName}`}
                  </Typography>
                  <Typography
                    component={"p"}
                    sx={{
                      marginTop: 2,
                      fontFamily: "system-ui",
                      fontSize: { xs: 12, md: 20 },
                    }}
                  >
                    {gameDetails.battingTeam.battingTeam === "Home"
                      ? `${gameDetails.stats.awayBowler1.bowler1AwayRuns} / ${gameDetails.stats.awayBowler1.bowler1AwayWickets} from ${gameDetails.stats.awayBowler1.bowler1AwayOvers}`
                      : `${gameDetails.stats.awayBat1.bat1HomeRuns} from ${gameDetails.stats.homeBat1.bat1HomeBalls} balls`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    justifyContent: "space-between",
                    width: { xs: "80dvw", md: 400 },
                    marginInline: "auto",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component={"p"}
                    sx={{
                      marginTop: 2,
                      fontFamily: "system-ui",
                      width: { xs: "35dvw", md: 200 },
                      fontSize: { xs: 14, md: 24 },
                    }}
                  >
                    {gameDetails.battingTeam.battingTeam === "Home"
                      ? `${gameDetails.stats.awayBowler2.bowler2AwayName}`
                      : `${gameDetails.stats.awayBat2.bat2AwayName}`}
                  </Typography>
                  <Typography
                    component={"p"}
                    sx={{
                      marginTop: 2,
                      fontFamily: "system-ui",
                      fontSize: { xs: 12, md: 20 },
                    }}
                  >
                    {gameDetails.battingTeam.battingTeam === "Home"
                      ? `${gameDetails.stats.awayBowler2.bowler2AwayRuns} / ${gameDetails.stats.awayBowler2.bowler2AwayWickets} from ${gameDetails.stats.awayBowler2.bowler2AwayOvers}`
                      : `${gameDetails.stats.awayBat2.bat2HomeRuns} from ${gameDetails.stats.homeBat2.bat2HomeBalls} balls`}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Paper>
      </Box>
      <GoogleAd />
    </Box>
  );
}

LiveScoringCricket.propTypes = {
  cricketGames: PropTypes.array,
};
