import styles from "../components/Login/css/styles.module.css";
import { useEffect } from "react";
import ScoringWizLogo from "../assets/LogoScoringWizLogo.png";
import { useNavigate } from "react-router-dom";

export default function FooterPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const homeClick = () => {
      navigate("/");
    };

    const logoElement = document.querySelector(`.${styles.footerLogo}`);
    logoElement.addEventListener("click", homeClick);
    document.addEventListener("logo", (event) => event.preventDefault());

    return () => {
      logoElement.removeEventListener("click", homeClick);
    };
  }, [navigate]);

  return (
    <footer className={styles.footerMain}>
      <section className={styles.footerCompDetails}>
        <img
          className={styles.footerLogo}
          src={ScoringWizLogo}
          alt="ScoringWiz Logo"
        />
        <p>&copy; CopyRight {new Date().getFullYear()}, ScoringWiz</p>
      </section>
      <section className={styles.footerLegalInfo}>
        <h4 className={styles.footerHeader}>Legal Info</h4>
        <p
          className={styles.footerItem}
          onClick={() => navigate("/terms-of-use")}
        >
          Terms of Use
        </p>
        <p
          className={styles.footerItem}
          onClick={() => navigate("/privacy-policy")}
        >
          Privacy Policy
        </p>
      </section>
      <section className={styles.footerCompInfo}>
        <h4 className={styles.footerHeader}>Company Info</h4>
        <p className={styles.footerItem} onClick={() => navigate("/about-us")}>
          About us
        </p>
        <p
          className={styles.footerItem}
          onClick={() => navigate("/contact-us")}
        >
          Contact us
        </p>
      </section>
    </footer>
  );
}
