import { Typography, Box } from "@mui/material";
const displayHomeSets = (setsNumber, gameDetails) => {
  if (setsNumber === 1) {
    return (
      <>
        <Typography variant="h3">{gameDetails.firstSetHome}</Typography>
        {gameDetails.firstSetTieHome > 0 ? (
          <Typography variant="body2">{gameDetails.firstSetTieHome}</Typography>
        ) : null}
      </>
    );
  } else if (setsNumber === 3) {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.firstSetHome}</Typography>
          {gameDetails.firstSetTieHome > 0 ? (
            <Typography variant="body2">
              {gameDetails.firstSetTieHome}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.secondSetHome}</Typography>
          {gameDetails.secondSetTieHome > 0 ? (
            <Typography variant="body2">
              {gameDetails.secondSetTieHome}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.thirdSetHome}</Typography>
          {gameDetails.thirdSetTieHome > 0 ? (
            <Typography variant="body2">
              {gameDetails.thirdSetTieHome}
            </Typography>
          ) : null}
        </Box>
      </Box>
    );
  } else if (setsNumber === 5) {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.firstSetHome}</Typography>
          {gameDetails.firstSetTieHome > 0 ? (
            <Typography variant="body2">
              {gameDetails.firstSetTieHome}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.secondSetHome}</Typography>
          {gameDetails.secondSetTieHome > 0 ? (
            <Typography variant="body2">
              {gameDetails.secondSetTieHome}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.thirdSetHome}</Typography>
          {gameDetails.thirdSetTieHome > 0 ? (
            <Typography variant="body2">
              {gameDetails.thirdSetTieHome}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.fourthSetHome}</Typography>
          {gameDetails.fourthSetTieHome > 0 ? (
            <Typography variant="body2">
              {gameDetails.fourthSetTieHome}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h3">{gameDetails.fifthSetHome}</Typography>
          {gameDetails.fifthSetTieHome > 0 ? (
            <Typography variant="body2">
              {gameDetails.fifthSetTieHome}
            </Typography>
          ) : null}
        </Box>
      </Box>
    );
  }
};

export default displayHomeSets;
