import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Box,
  Backdrop,
  Paper,
  Typography,
} from "@mui/material";
import ScoringWizLogo from "../../assets/LogoScoringWizLogo.png";
import "./liveScoringCSS.css";
import ScoringWiz from "../../assets/android-chrome-192x192.png";
import GoogleAd from "./GoogleAd";

export default function LiveScoringTeam({ rugbyGames }) {
  const { gameId } = useParams();
  const [gameDetails, setGameDetails] = useState(null);

  useEffect(() => {
    // Fetch the game details based on gameId
    const selectedGame = rugbyGames.find((game) => game.id === gameId);
    setGameDetails(selectedGame);
  }, [gameId, rugbyGames]);

  if (!gameDetails) {
    return (
      <Backdrop open={!gameDetails}>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  }

  const displayTimer = () => {
    if (!gameDetails.timer.isHalfTime) {
      if (gameDetails.timer.half === 1) {
        return (
          <Box sx={{ textAlign: "center" }}>
            {gameDetails.timer.firstHalftime < 10 ? (
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Audiowide, system-ui",
                  fontSize: { xs: 12, md: 25 },
                }}
              >
                0{gameDetails.timer.firstHalftime} minutes left in the 1
                <sup>st</sup> half
              </Typography>
            ) : (
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Audiowide, system-ui",
                  fontSize: { xs: 12, md: 25 },
                }}
              >
                {gameDetails.timer.firstHalftime} minutes left in the 1
                <sup>st</sup> half
              </Typography>
            )}
          </Box>
        );
      } else {
        return (
          <Box sx={{ textAlign: "center", fontSize: { xs: 22, md: 25 } }}>
            {gameDetails.timer.firstHalftime < 10 ? (
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Audiowide, system-ui",
                  fontSize: { xs: 12, md: 25 },
                }}
              >
                0{gameDetails.timer.secondHalftime} minutes left in the game
              </Typography>
            ) : (
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "Audiowide, system-ui",
                  fontSize: { xs: 12, md: 25 },
                }}
              >
                {gameDetails.timer.secondHalftime} minutes left in the game
              </Typography>
            )}
          </Box>
        );
      }
    } else {
      return (
        <Typography
          variant="h4"
          ssx={{
            fontFamily: "Audiowide, system-ui",
            fontSize: { xs: 12, md: "inherit" },
          }}
        >
          Half Time
        </Typography>
      );
    }
  };

  const goHome = () => {
    window.location.href = `${import.meta.env.VITE_FRONTEND_URL}`;
  };

  return (
    <Box component={"main"}>
      <Box
        component={"section"}
        sx={{ height: { xs: 100, md: 173 }, width: { xs: 140, md: 245 } }}
        onClick={goHome}
      >
        <img
          src={ScoringWizLogo}
          alt="ScoringWiz Logo"
          style={{ borderRadius: "0 100px 100px 0", cursor: "pointer" }}
        />
      </Box>
      <Box
        component={"section"}
        sx={{
          height: {
            xs: "calc(100dvh - 100px - 80px)",
            md: "calc(100dvh - 173px - 120px)",
          },
          width: { xs: "80dvw", md: "50dvw" },
          marginInline: "auto",
          alignContent: "center",
        }}
      >
        <Paper
          elevation={4}
          sx={{
            paddingX: { xs: 1, md: 2 },
            paddingY: { xs: 3, md: 8 },
            marginTop: 2,
          }}
        >
          <Paper
            elevation={10}
            sx={{
              width: "95%",
              marginInline: "auto",
              padding: { xs: 0.75, md: 1.5 },
            }}
          >
            {displayTimer()}
          </Paper>
          <Box
            sx={{
              display: { xs: "grid", md: "flex" },
              justifyContent: "space-evenly",
              marginTop: 2,
            }}
          >
            <Paper
              elevation={10}
              sx={{
                textAlign: "center",
                width: { xs: 140, md: 230 },
                height: { xs: 100, md: 140 },
                color: "#053F5C",
              }}
            >
              <Typography
                variant="h5"
                component={"p"}
                sx={{
                  marginTop: 2,
                  fontFamily: "Audiowide, system-ui",
                  fontSize: { xs: 12, md: 20 },
                }}
              >
                {gameDetails.homeName}
              </Typography>
              <Typography
                variant="h2"
                component={"p"}
                sx={{
                  marginTop: 2,
                  fontFamily: "Audiowide, system-ui",
                  fontSize: { xs: 48, md: 64 },
                }}
              >
                {gameDetails.homeScore}
              </Typography>
            </Paper>
            <Typography
              sx={{
                alignSelf: "center",
                fontFamily: "Audiowide, system-ui",
                textAlign: "center",
              }}
              variant="h3"
              component={"p"}
              color="primary"
            >
              vs
            </Typography>
            <Paper
              elevation={10}
              sx={{
                textAlign: "center",
                width: { xs: 140, md: 230 },
                height: { xs: 100, md: 140 },
                color: "#FD5901",
              }}
            >
              <Typography
                variant="h5"
                component={"p"}
                sx={{
                  marginTop: 2,
                  fontFamily: "Audiowide, system-ui",
                  fontSize: { xs: 12, md: 20 },
                }}
              >
                {gameDetails.awayName}
              </Typography>
              <Typography
                variant="h2"
                component={"p"}
                sx={{
                  marginTop: 2,
                  fontFamily: "Audiowide, system-ui",
                  fontSize: { xs: 48, md: 64 },
                }}
              >
                {gameDetails.awayScore}
              </Typography>
            </Paper>
          </Box>
          <Box
            sx={{
              height: { xs: 55, md: 125 },
              marginTop: 0.5,
              backgroundImage: `url(${ScoringWiz})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              backgroundSize: { xs: "55px 55px", md: "125px 125px" },
              opacity: 0.25,
            }}
          ></Box>
        </Paper>
      </Box>
      <GoogleAd />
    </Box>
  );
}

LiveScoringTeam.propTypes = {
  rugbyGames: PropTypes.array.isRequired,
};
