import { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage.jsx";
import { useQuery } from "@tanstack/react-query";
import { load_user, checkAuthenticated } from "./redux/actions/auth.js";
import LazyLoadingPage from "./utils/LazyLoadingPage.jsx";
import MetaTags from "./utils/MetaTags.js";
import schoolInfo from "./utils/schoolInfo.js";

const ScoringPage = lazy(() => import("./components/ScoringPage.jsx"));
const RugbyScoringPage = lazy(
  () => import("./components/Rugby/RugbyScoringPage.jsx")
);
const TennisScoringPage = lazy(
  () => import("./components/Tennis/TennisScoringPage.jsx")
);
const NetballScoringPage = lazy(
  () => import("./components/Netball/NetballScoringPage.jsx")
);
const CricketScoringPage = lazy(
  () => import("./components/Cricket/CricketScoringPage.jsx")
);
const AthletesPage = lazy(
  () => import("./components/Athletes/AthletesPage.jsx")
);
const LoginPage = lazy(() => import("./components/Login/LoginPage.jsx"));
const RegisterPage = lazy(() => import("./components/Login/RegisterPage.jsx"));
const ForgotPassword = lazy(() => import("./components/Login/Components/ForgotPassword.jsx"));
const ActivePage = lazy(() => import("./components/Login/Components/ActivePage.jsx"));
const Activate = lazy(() => import("./components/Login/Components/Activate.jsx"));
const ResetPasswordConfirm = lazy(() => import("./components/Login/Components/ResetPasswordConfirm.jsx"));
const MyAccount = lazy(() => import("./components/My Account/my_account.jsx"));
const RugbyStats = lazy(() => import("./components/Stats/RugbyStats.jsx"));
const NetballStats = lazy(() => import("./components/Stats/NetballStats.jsx"));
const HockeyScoringPage = lazy(() => import("./components/Hockey/HockeyScoringPage.jsx"));
const HockeyStats = lazy(() => import("./components/Stats/HockeyStats.jsx"));
const TennisStats = lazy(() => import("./components/Stats/TennisStats.jsx"));
const CricketStats = lazy(() => import("./components/Stats/CricketStats.jsx"));
const About = lazy(() => import("./components/About.jsx"));
const ContactUs = lazy(() => import("./components/Contact.jsx"));
const Terms = lazy(() => import("./components/Terms.jsx"));
const Policy = lazy(() => import("./components/Policy.jsx"));

function AppRouter() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  const { data, refetch, isLoading, isError, error } = useQuery({
    queryKey: ["schoolInfo"],
    queryFn: () => schoolInfo({ isAuthenticated }),
    enabled: isAuthenticated,
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch(checkAuthenticated());
      dispatch(load_user());
      refetch();
    };

    fetchData();
  }, [dispatch, refetch]);

  const school_type = data?.school_type;
  const school_name = data?.school_name;
  const school_instance = data?.id;
  const schoolLogo = data?.school_logo;
  const sportSelected = {
    'cricket': data?.cricket,
    'rugby': data?.rugby,
    'netball': data?.netball,
    'tennis': data?.tennis,
    'hockey': data?.hockey,
  }

  return (
    <>
      <MetaTags />
      <Routes>
        <Route path="/" element={<HomePage isAuthenticated={isAuthenticated} data={data} />} />
        <Route path="/scoring">
          <Route
            index
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <ScoringPage isAuthenticated={isAuthenticated} sportSelected={sportSelected} data={data} />
              </Suspense>
            }
          />
          <Route
            path="rugby"
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <RugbyScoringPage
                  school_type={school_type}
                  school_name={school_name}
                  school_instance={school_instance}
                  isAuthenticated={isAuthenticated}
                />
              </Suspense>
            }
          />
          <Route
            path="tennis"
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <TennisScoringPage
                  school_type={school_type}
                  school_name={school_name}
                  school_instance={school_instance}
                  isAuthenticated={isAuthenticated}
                />
              </Suspense>
            }
          />
          <Route
            path="netball"
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <NetballScoringPage
                  school_type={school_type}
                  school_name={school_name}
                  school_instance={school_instance}
                  isAuthenticated={isAuthenticated}
                />
              </Suspense>
            }
          />
          <Route
            path="cricket"
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <CricketScoringPage
                  school_type={school_type}
                  school_name={school_name}
                  school_instance={school_instance}
                  isAuthenticated={isAuthenticated}
                />
              </Suspense>
            }
          />
          <Route
            path="hockey"
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <HockeyScoringPage
                  school_type={school_type}
                  school_name={school_name}
                  school_instance={school_instance}
                  schoolLogo={schoolLogo}
                  isAuthenticated={isAuthenticated}
                />
              </Suspense>
            }
          />
        </Route>
        <Route
          exact
          path="/athletes"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <AthletesPage
                school_instance={school_instance}
                school_type={school_type}
                school_name={school_name}
                data={data}
              />
            </Suspense>
          }
        />
        <Route
          exact
          path="/login"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <LoginPage />
            </Suspense>
          }
        />
        <Route
          exact
          path="/password/reset/confirm/:uid/:token"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <ResetPasswordConfirm />
            </Suspense>
          }
        />
        <Route
          exact
          path="/reset-password"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="/activate/:uid/:token"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <ActivePage />
            </Suspense>
          }
        />
        <Route
          exact
          path="/register"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <RegisterPage />
            </Suspense>
          }
        />
        <Route
          exact
          path="/activate"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <Activate />
            </Suspense>
          }
        />
        <Route
          exact
          path="/my_account"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <MyAccount data={data} isLoading={isLoading} isError={isError} error={error} />
            </Suspense>
          }
        />
        <Route path="/stats">
          <Route
            exact
            path="rugby"
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <RugbyStats data={data} />
              </Suspense>
            }
          />
          <Route
            exact
            path="netball"
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <NetballStats data={data} />
              </Suspense>
            }
          />
          <Route
            exact
            path="hockey"
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <HockeyStats data={data} />
              </Suspense>
            }
          />
          <Route
            exact
            path="tennis"
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <TennisStats data={data} />
              </Suspense>
            }
          />
          <Route
            exact
            path="cricket"
            element={
              <Suspense fallback={<LazyLoadingPage />}>
                <CricketStats data={data} />
              </Suspense>
            }
          />
        </Route>
        <Route
          exact
          path="/about-us"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <About isAuthenticated={isAuthenticated} data={data} />
            </Suspense>
          }
        />
        <Route
          exact
          path="/contact-us"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <ContactUs isAuthenticated={isAuthenticated} data={data} />
            </Suspense>
          }
        />
        <Route
          exact
          path="/terms-of-use"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <Terms isAuthenticated={isAuthenticated} data={data} />
            </Suspense>
          }
        />
        <Route
          exact
          path="/privacy-policy"
          element={
            <Suspense fallback={<LazyLoadingPage />}>
              <Policy isAuthenticated={isAuthenticated} data={data} />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default AppRouter;
